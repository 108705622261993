.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.wrapHeader img {
  width: 20%;
}

.wrapHeader {
  text-align: center;
  padding: 20px;
}

.usage {
  font-family: "spartan";
  color: #140f2d;
}

.title {
  font-family: "spartan";
  background-image:
  linear-gradient(
    to right, 
    #4a7fe9 0%,
    #4b49e9 20%,
    #8816c1 40%,
    #9b0d43 60%,
    #d21d40 75%,
    #e94014 85%,
    #e56d13 100% 
  );
  width: 100%;
  display: flex;
  justify-content: center; 
}

.App-logo {
  width: 30%;
  }

.subtitle {
  color: "#140f2d"; 
  margin-left: "20px";
  font-family: "spartan";
}

.downloadableLogo {
  margin: 20px;
  padding: 20px;
  height: 80px;
  border-radius: 15px;
  /* box-shadow: #e9e8fc 6px 6px 2px; */
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);
}

.downloadableLogoWhite {
  background-color: #140f2d;
}

.btn {
  font-family: "spartan";
  background-color: white;
  border: none;
  cursor: pointer;
}

.downloadableTypo {
  display: "flex";
  color: #140f2d;
  margin: 80px;
  font-size: 20;
  text-align: "center";
}

button:focus { outline: none; }